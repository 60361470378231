import { Formik, Field } from 'formik';
import { basicInformationAddressSchema } from 'pages/profile/schema';
import React, { useContext, useEffect, useState } from 'react';
import { RegionDropdown, CountryDropdown } from 'react-country-region-selector';
import { toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  Row,
  Col,
  Label,
  Input,
  CardBody,
  FormGroup,
} from 'reactstrap';
import useUserService from 'services/profile/user.service';
import { AppContext } from 'store/app.context';

const BillingAddressBasicInformation = () => {
  const { languageState, userState } = useContext(AppContext);
  const { addAddress, getAddress } = useUserService();

  const [initialValues, setValues] = useState({
    VATNumber: '',
    companyName: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    country: 'Belgium',
  });

  useEffect(() => {
    const billingAddress = userState?.userInfo?.address?.filter(
      (address) => address?.type === 'BILLING'
    );
    if (billingAddress?.length) {
      setValues({
        ...billingAddress[billingAddress?.length - 1],
      });
    }
  }, [userState]);

  const submitForm = async (values) => {
    const billingAddress = userState?.basicInformation?.address?.filter(
      (address) => address?.type === 'BILLING'
    );

    if (billingAddress && billingAddress?.length === 0) {
      const result = await addAddress({
        ...values,
        type: 'BILLING',
      });
      if (result && result.status === 201) {
        toast.success(languageState.translation.ALERT.ADDRESS_SAVED, {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    } else {
      if (billingAddress?.length) {
        const result = await addAddress({
          id: billingAddress[0]?.id,
          ...values,
          type: 'BILLING',
        });
        if (result && result.status === 201) {
          toast.success(languageState.translation.ALERT.ADDRESS_SAVED, {
            position: 'bottom-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      }
    }
  };
  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="12">
            <h3 className="mb-0">Billing address</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={basicInformationAddressSchema}
          onSubmit={(values) => submitForm(values)}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            handleChange,
          }) => (
            <div>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <Field
                        className="form-control"
                        id="input-vat-number2"
                        placeholder={'VAT-number'}
                        type="text"
                        name="VATNumber"
                      />
                      {errors.VATNumber && touched.VATNumber && (
                        <div className="invalid-feedback">
                          {errors.VATNumber}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Field
                        className="form-control"
                        id="input-company-name2"
                        placeholder={'Company name'}
                        type="text"
                        name="companyName"
                      />
                      {errors.companyName && touched.companyName && (
                        <div className="invalid-feedback">
                          {errors.companyName}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <Field
                        className="form-control"
                        id="input-street2"
                        placeholder={'Street'}
                        type="text"
                        name="street"
                      />
                      {errors.street && touched.street && (
                        <div className="invalid-feedback">{errors.street}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Field
                        className="form-control"
                        id="input-last-name"
                        placeholder={'House number'}
                        type="text"
                        name="houseNumber"
                      />
                      {errors.houseNumber && touched.houseNumber && (
                        <div className="invalid-feedback">
                          {errors.houseNumber}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <Field
                        className="form-control"
                        id="input-postal-code-2"
                        placeholder={'Postal Code'}
                        type="text"
                        name="postalCode"
                      />
                      {errors.postalCode && touched.postalCode && (
                        <div className="invalid-feedback">
                          {errors.postalCode}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Input
                        placeholder={languageState.translation.COMMON.CITY}
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                      />
                      {/* <RegionDropdown
                        className="form-control"
                        id="input-city"
                        placeholder={'City'}
                        type="text"
                        value={values.city}
                        name="city"
                        onChange={(val) => {
                          setValues({
                            ...values,
                            city: val,
                          });
                        }}
                        country={values.country}
                      /> */}
                      {values.city && touched.city && (
                        <div className="invalid-feedback">{errors.city}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col md="6">
                  <div className="pl-lg-4">
                    <FormGroup>
                      <CountryDropdown
                        className="form-control"
                        id="input-country"
                        placeholder={languageState.translation.PROFILE.COUNTRY}
                        type="text"
                        name="country"
                        value={values.country}
                        onChange={(val) => {
                          setValues({
                            ...values,
                            country: val,
                          });
                        }}
                      />
                      {errors.country && touched.country && (
                        <div className="invalid-feedback">{errors.country}</div>
                      )}
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <button
                className="btn bg-exprimary mt-2 ml-auto d-block"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
                type="submit"
              >
                {languageState.translation.COMMON.BUTTONS.SAVE_INFO}
              </button>
            </div>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default BillingAddressBasicInformation;
